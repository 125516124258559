const CONSTANTS = {
    BUILD_VERSION_STRING: process.env.REACT_APP_BUILD_VERSION_STRING || '',
    CONTEXT_ROOT: process.env.REACT_APP_PUBLIC_URL ? (process.env.REACT_APP_PUBLIC_URL + (process.env.REACT_APP_PUBLIC_URL.substring(process.env.REACT_APP_PUBLIC_URL.length - 1) !== '/' ? '/' : '')) : '',
    API_ROOT: process.env.REACT_APP_API_ROOT ? (process.env.REACT_APP_API_ROOT + (process.env.REACT_APP_API_ROOT.substring(process.env.REACT_APP_API_ROOT.length  -1) !== '/' ? '/' : '')) : '',
    //GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_CLIENT_KEY,
    REQUIRED_IMAGES_FOR_MINOR: [], // SET FROM INDEX
    CROP_TOOL_DIMENSIONS: {}, // SET FROM INDEX
    GOOGLE_RECAPTCHA_KEY: null, // SET FROM INDEX
    STRIPE_KEY: null, // SET FROM INDEX
    PAYPAL_KEY: null, // SET FROM INDEX (NOTE IT IS UNUSED)
    PRICES: null, // SET FROM INDEX
    SERVER_TIME: null, // SET FROM INDEX
    ISRAEL_CLOCK_TIME: null, // SET FROM INDEX
    PING_PONG_FREQUENCY: 60,
    DATE_FORMAT_MOMENT: 'DD/MM/YYYY',
    DATE_FORMAT_DATEPICKER: 'dd/MM/yyyy',
    LANGUAGE_SELECT_ENABLED: true,
    YOUTUBE_ON_HEADER_ENABLED: false,
    OCR_ENABLED: true,
    WHATSAPP_ICON_ENABLED: false,
    INACTIVE_FIELDS: { stage2: [ ] }, // SET FROM INDEX
    YOUTUBE_LINK: '', // SET FROM INDEX
    SUPPORT_PHONE_NUMBER: '',
    SUPPORT_EMAIL: '',
    VISA_READY_HOURS: 24,
    SYSTEM_DISABLED: 'no',
    FILESIZE_MAX_LIMIT: 5800000, // SET FROM INDEX
    FILESIZE_MIN_LIMIT: 50000,
    MEDIA_BREAKPOINTS_MOBILE: 960,
    POST_DEFAULT_OPTIONS: {
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    },
    VISA_TYPES_TIMING_MAPPING: {
        '30days-single': 1,
        '30days-multi': 1,
        '90days-single': 1,
        '90days-multi': 1,
        '30days-multi-5': 1
    },
    EMAIL_REGEX: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/, // eslint-disable-line
    SHEKEL: '₪',
    DOLLAR: '$'
};

export default CONSTANTS;
