/* eslint-disable eqeqeq */
import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {
    getApiRoot,
    getContextRoot,
    getCountriesList, getExtensionByFileContent,
    getFileType,
    getLanguageValue,
    googleRecaptcha,
    navigateToPath
} from "../../common/functions";
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import lodashGet from 'lodash/get';
import Loader from '../Loader';
import { CSS_COLORS } from '../../common/cssColors';
import CONSTANTS from '../../common/constants';
import ModalComponent from "../Modal";
import Button from '@material-ui/core/Button';
import lodashSet from "lodash/set";
import CropHandler from "../CropHandler";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import GenericFieldComponent from "../GenericFieldComponent";
import moment from "moment";
import {ACTIONS} from "../../redux/actions";

function RegisterStage1OcrSubProcessComponent(props) {
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const dispatch = useDispatch();
    const [ isOpen, setIsOpen ] = useState(true);
    const [ loaders, setLoaders ] = useState({});
    const [ isImageReady, setIsImageReady ] = useState(false);
    const [ fileUploadData, setFileUploadData ] = useState({});
    const [ errors, setErrors ] = useState({});
    const [ activeStage, setActiveStage ] = useState('passport-upload'); // 1) passport-upload 2) fields-confirmation
    const [ matchedPassportType, setMatchedPassportType ] = useState('unknown');
    const STAGE_MAPPING = {
        'passport-upload': 1,
        'fields-confirmation': 2
    };
    let [ cropHandlerControls , setCropHandlerControls ] = useState({
        passport: {}
    });
    const [ fieldErrors, setFieldErrors ] = useState({});
    const [ fieldsData, setFieldsData ] = useState({
        birthDate: null,
        expirationDate: '',
        firstName: '',
        lastName: '',
        gender: 'select',
        issuanceDate: '',
        nationality: '', // not used currently
        passportIssuingPlace: '', // not used currently
        passportNumber: '',
        passportType: '' // not used currently
    });
    cropHandlerControls.passport.callback = cropHandlerCallback.bind({ fileType: 'passport' });

    const FIELDS = [
        {
            key: 'lastName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.lastName ? ' field-error' : ''),
                    error: fieldErrors.lastName || false,
                    value: fieldsData.lastName,
                    inputProps: {
                        name: 'lastName'
                    },
                    helperText: fieldErrors.lastName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'lastName'}),
                    label: getLanguageValue('register-individual.stage2-family-name-english')
                }
            }
        },
        {
            key: 'firstName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.firstName ? ' field-error' : ''),
                    error: fieldErrors.firstName || false,
                    value: fieldsData.firstName,
                    inputProps: {
                        name: 'firstName'
                    },
                    helperText: fieldErrors.firstName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'firstName'}),
                    label: getLanguageValue('register-individual.stage2-first-name-english')
                }
            }
        },
        {
            key: 'gender',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'gender',
                selectLabel: getLanguageValue('register-individual.stage2-gender'),
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    { value: 'male', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-gender-male') },
                    { value: 'female', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-gender-female') }
                ],
                selectFormClassName: 'field-component' + (fieldErrors.gender ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.gender || false,
                    value: fieldsData.gender,
                    inputProps: {
                        name: 'gender',
                        id: 'gender'
                    },
                    helperText: fieldErrors.gender || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'birthDate',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.birthDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.birthDate || false,
                    autoOk: true,
                    value: fieldsData.birthDate !== '' ? fieldsData.birthDate : null,
                    inputProps: {
                        name: 'birthDate',
                        id: 'birthDate'
                    },
                    helperText: fieldErrors.birthDate || '',
                    onChange: (value) => {
                        DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'birthDate' })(value);
                    },
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'birthDate'}),
                    label: getLanguageValue('register-individual.stage2-date-of-birth')
                }
            }
        },
        {
            key: 'issuanceDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#issuanceDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.issuanceDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.issuanceDate || false,
                    autoOk: true,
                    value: fieldsData.issuanceDate !== '' ? fieldsData.issuanceDate : null,
                    inputProps: {
                        name: 'issuanceDate',
                        id: 'issuanceDate'
                    },
                    helperText: fieldErrors.issuanceDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'issuanceDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'issuanceDate'}),
                    label: getLanguageValue('register-individual.stage2-issuance-date') + ' *'
                }
            }
        },
        {
            key: 'expirationDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#expirationDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: (value) => { let currentDate = moment(moment().add('6','months').format('YYYY-MM-DD')); value = document.querySelector('#expirationDate').value; if (moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid() && moment(value, 'DD/MM/YYYY').diff(currentDate) <= 0) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-expiration-date-must-be-above-x-months')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.expirationDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.expirationDate || false,
                    value: fieldsData.expirationDate,
                    inputProps: {
                        name: 'expirationDate',
                        id: 'expirationDate'
                    },
                    helperText: fieldErrors.expirationDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'expirationDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'expirationDate'}),
                    label: getLanguageValue('register-individual.stage2-expiration-date')
                }
            }
        },
        /*{
            key: 'passportIssuingPlace',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.passportIssuingPlace ? ' field-error' : ''),
                    error: fieldErrors.passportIssuingPlace || false,
                    value: fieldsData.passportIssuingPlace,
                    inputProps: {
                        name: 'passportIssuingPlace'
                    },
                    helperText: fieldErrors.passportIssuingPlace || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'passportIssuingPlace'}),
                    label: getLanguageValue('register-individual.stage2-issuing-city')
                }
            }
        },*/
        {
            key: 'passportNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: props.languageKey == 'he' ? DEFAULT_NUMERIC_VALUE_VALIDATOR : DEFAULT_ENG_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.passportNumber ? ' field-error' : ''),
                    error: fieldErrors.passportNumber || false,
                    value: fieldsData.passportNumber,
                    inputProps: {
                        name: 'passportNumber'
                    },
                    helperText: fieldErrors.passportNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'passportNumber'}),
                    label: getLanguageValue('register-individual.stage2-passport-number')
                }
            }
        },
    ];

    const {
        actions
    } = props;

    return <ModalComponent
        isOpen={isOpen}
        modalClassName={'register-individual-stage1-subprocess-modal'}
        disableBackdropClick={true}
        showTitleCloseButton={true}
        onClose={(src) => { if (isLoaderActive()) { return; } setIsOpen(false); actions.onClose('abort'); }}
        title={getLanguageValue('register-individual.stage1-ocr-subprocess-scan-passport-title') + ' ' + getLanguageValue('register-individual.stage1-ocr-subprocess-scan-passport-stage') + ' ' + STAGE_MAPPING[activeStage]}
        content={<RegisterStage1OcrSubProcessStyle>
            {activeStage == 'passport-upload' && <div className={'passport-upload-stage-container'}>
                <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage1-ocr-subprocess-explanation-stage1') }} />
                <div className={'sample-image-container'}><img src={getContextRoot() + 'images/sample_passport.jpg'} alt={''} /></div>
                <div className={'uploaded-image-container'}><br/>{lodashGet(fileUploadData, 'passport.base64') ? <img alt="" id={'uploaded-image-passport'} className={"uploaded-image passport"} src={lodashGet(fileUploadData, 'passport.base64')} /> : null}</div>
                <div className={'buttons-container'}>
                    <Button className={'create-or-upload-image-button common-style-primary-button'} onClick={() => { if (isLoaderActive()) return; document.querySelector('#stage1-passport-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage1-ocr-subprocess-create-or-upload-image')}</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {isImageReady && <><Button className={'scan-button common-style-primary-button'} onClick={scan}>{getLanguageValue('register-individual.stage1-ocr-subprocess-scan')} {loaders.scan && <>&nbsp;&nbsp;<Loader className={'scan-loader'} /></>}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    <Button className={'skip-button'} onClick={() => { if (isLoaderActive()) return; actions.onClose('skip'); }}>{getLanguageValue('register-individual.stage1-ocr-subprocess-skip')}</Button>
                </div>
                {<div className={'network-error-message' + (errors.network ? ' visible' : '')}>{getLanguageValue('register-individual.stage1-ocr-subprocess-error-network')}</div>}
                <input type="file" id="stage1-passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passport', '.upload-passport-container'); }} />
                <div><CropHandler controls={cropHandlerControls.passport} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['passportMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['passportDesktop']} imageCssSelector={'.uploaded-image.passport'} imageSrc={lodashGet(fileUploadData, 'passport.base64')} /></div>
            </div>}
            {activeStage == 'fields-confirmation' && <div className={'fields-confirmation-container'}>
                <div dangerouslySetInnerHTML={{ __html:  getLanguageValue('register-individual.stage1-ocr-subprocess-explanation-stage2') }} />
                <div>
                    <Grid container className={"upload-passport-and-form-fields-container"} spacing={3} >
                        <Grid item className={"form-fields-container"} md={8}>
                            <Grid container className={"fields-container"}>

                                {FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container " + fieldItem.key} md={6}>
                                    <GenericFieldComponent {...fieldItem.props} />
                                </Grid>))}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={'stage2-buttons-container'}>
                    <Button className={'scan-button common-style-primary-button'} onClick={finish}>{getLanguageValue('register-individual.stage1-ocr-subprocess-create-or-finish')}</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={() => { setActiveStage('passport-upload'); setIsImageReady(false); setFileUploadData({}); }} className={'retry-scanning-button common-style-primary-button'}>{getLanguageValue('register-individual.stage1-ocr-subprocess-retry-scanning')}</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button className={'skip-button'} onClick={() => { if (isLoaderActive()) return; actions.onClose('skip'); }}>{getLanguageValue('register-individual.stage1-ocr-subprocess-skip')}</Button>
                </div>
            </div>}

            <GlobalStyle />
        </RegisterStage1OcrSubProcessStyle>}
    />;

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, moment(date).format('YYYY-MM-DD'));
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    function DEFAULT_ON_BLUR() {
        let matchedField = FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_HEB_STRING_VALUE_VALIDATOR(value) {
        if (!DEFAULT_ENG_STRING_VALUE_VALIDATOR(value)) {
            if (!DEFAULT_HEB_STRING_VALUE_VALIDATOR(value)) {
                return false;
            }
        }
        return true;
    }

    function DEFAULT_ENG_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([a-zA-Z\"\'\.\-,\s]+)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_HEB_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([\u0590-\u05FF\"\'\.\-,\s]+)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^(([0-9]+)|([a-zA-Z]+))+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function isLoaderActive() {
        for (let key in loaders) {
            if (loaders[key]) {
                return true;
            }
        }
        return false;
    }

    function onFileUploadChange(e, fieldName, containerCssSelector) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';
        setIsImageReady(false);

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic'].indexOf(fileTypeResultExtension) == -1) {
                setFileUploadData({ ...fileUploadData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-type-error'), base64: null, filename: '' } });
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setFileUploadData({ ...fileUploadData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-too-small-error'), base64: null, filename: '' } });
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setFileUploadData({ ...fileUploadData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-size-error'), base64: null, filename: '' } });
                return;
            }
            // activate crop tool
            setTimeout(() => {
                setFileUploadData({ ...fileUploadData, [fieldName]: { error: false, base64: reader.result, filename: localFilename + '.' + fileTypeResultExtension, editMode: true } });
                setTimeout(() => {
                    cropHandlerControls[fieldName].restart();
                }, 100);
            }, 100);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function cropHandlerCallback(croppedImage) {
        lodashSet(fileUploadData, this.fileType + '.editMode', false);
        if (croppedImage !== false) {
            fileUploadData[this.fileType].base64 = croppedImage;
            lodashSet(fileUploadData, this.fileType + '.previewOnly', false);
            setIsImageReady(true);
        }
        setFileUploadData({ ...fileUploadData });
    }

    async function scan() {
        if (loaders.scan) {
            return;
        }
        setLoaders({ scan: true });
        setErrors({ ...errors, network: false });
        // MOCK MODE (TODO - REMOVE THIS)
        //{"err":null,"data":{"success":true,"output":{"str":"mock","fields":{"passportType":"P","passportNumber":"123456789","firstName":"mock","lastName":"test","nationality":"IND","gender":"male","dateOfBirth":"83-10-05","passportIssuingPlace":"ISRAEL","issueDate":"21-12-12","expiryDate":"22-02-02"},"found":{"passportType":true,"passportNumber":true,"firstName":true,"lastName":true,"nationality":true,"gender":true,"dateOfBirth":true,"passportIssuingPlace":true,"issueDate":true,"expiryDate":true}}}}
        //setActiveStage('fields-confirmation');
        try {
            let url = getApiRoot() + 'ocr-passport-scan/';
            let recaptchaToken = await googleRecaptcha();
            let fileContentBase64 = fileUploadData.passport.base64.substring(fileUploadData.passport.base64.indexOf(',') + 1);
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    fileContent: fileContentBase64,
                    filename: fileUploadData.passport.filename
                })
            };
            let result = await (await fetch(url, options)).json();
            if (!lodashGet(result, 'data.success')) {
                setErrors({ ...errors, network: true });
                setLoaders({ scan: false });
                return;
            }
            if (lodashGet(result, 'data.output.fields')) {
                let datesFields = [
                    { key: 'expirationDate', type: 'new', maxDeltaFromNow: 10 },
                    { key: 'issuanceDate', type: 'old' },
                    { key: 'birthDate', type: 'old' }
                ];
                for (let dateFieldObj of datesFields) {
                    let dateField = dateFieldObj.key;
                    let dateFieldType = dateFieldObj.type;
                    let dateFieldMaxDeltaFromNow = dateFieldObj.maxDeltaFromNow;
                    if (moment(result.data.output.fields[dateField], 'YY-MM-DD', true).isValid()) {
                        result.data.output.fields[dateField] = moment(result.data.output.fields[dateField], 'YY-MM-DD').format('YYYY-MM-DD');
                        let currentYear = parseInt(moment().format('YYYY'));
                        let dateFieldYear = parseInt(moment(result.data.output.fields[dateField]).format('YYYY'));
                        if (dateFieldType == 'old' && dateFieldYear > currentYear) {
                            result.data.output.fields[dateField] = moment(result.data.output.fields[dateField]).add('-100', 'years').format('YYYY-MM-DD');
                        }
                        else if (dateFieldType == 'new' && dateFieldYear < currentYear && dateFieldYear+100 <= currentYear+dateFieldMaxDeltaFromNow) {
                            result.data.output.fields[dateField] = moment(result.data.output.fields[dateField]).add('100', 'years').format('YYYY-MM-DD');
                        }
                    }
                }
                setFieldsData(result.data.output.fields);
                setMatchedPassportType(result.data.output.matchedPassportType);
            }
            setLoaders({ scan: false });
            setActiveStage('fields-confirmation');
        }
        catch (err) {
            setErrors({ ...errors, network: true });
            setLoaders({ scan: false });
        }
    }

    async function finish() {
        // save found data and go to next page
        let customMapping = {
            firstName: 'firstNameEnglish',
            lastName: 'lastNameEnglish'
        };
        let ignoredFields = [ 'nationality', 'passportType', 'passportIssuingPlace' ];
        let updateList = [];
        for (let key in fieldsData) {
            if (ignoredFields.indexOf(key) == -1 && fieldsData[key]) {
                updateList.push({ path: 'registerIndividualStagesData.stage2Data.' + (customMapping[key] || key), value: fieldsData[key] });
            }
        }
        if (fieldsData.passportIssuingPlace) {
            updateList.push({ path: 'registerIndividualStagesData.stage2Data.issuingCity', value: fieldsData.passportIssuingPlace });
        }
        if (matchedPassportType == 'india') {
            // auto-select india as country of birth + citizenship + issue country
            updateList.push({ path: 'registerIndividualStagesData.stage2Data.countryOfBirth', value: 'India' });
            updateList.push({ path: 'registerIndividualStagesData.stage2Data.countryOfCitizenship', value: 'India' });
            updateList.push({ path: 'registerIndividualStagesData.stage2Data.issuingCountry', value: 'India' });
            updateList.push({ path: 'registerIndividualStagesData.stage2Data.flightCountryOrigin', value: 'India' });
        }
        // add passport field
        updateList.push({
            path: 'registerIndividualStagesData.uploadedFiles',
            value: [{
                fileType: 'passport',
                filename: lodashGet(fileUploadData, 'passport.filename') || getExtensionByFileContent(lodashGet(fileUploadData, 'passport.base64')),
                fileContent: lodashGet(fileUploadData, 'passport.base64')
            }]
        });
        if (updateList.length) {
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: updateList
            });
        }

        setTimeout(() => {
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage2');
        }, 10);
    }
}

const RegisterStage1OcrSubProcessStyle = styled.div`
   .single-field-container {
    width: 100%;
    margin-bottom: 25px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      &.firstName, &.lastName, &.gender, &.issuanceDate, &.passportNumber, &.passportIssuingPlace {
        width: 50%;
      }

    }
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      > .MuiInputLabel-formControl {
        font-size: 13px;
      }
    }
  }
  .sample-image-container {
    text-align: center;
    img {
      max-width: 100%;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        max-width: 300px;
      }
    }
  }
  .uploaded-image-container {
    text-align: center;
  }
  #uploaded-image-passport {
    max-width: 500px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      max-width: 100%;
    }
  }
  .buttons-container {
    margin-top: 10px;
    text-align: center;
    > button {
      min-width: 100px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        margin-top: 10px;
        width: 100%;
      }
    }
    .scan-button {
      background-color: green !important;
    }
  }
  .skip-button {
    border: 1px solid black !important;
    font-size: 24px !important;
  }
  .network-error-message {
    text-align: center;
    margin-top: 5px;
    color: ${CSS_COLORS.FIELD_WARNING};
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  #stage1-passport-file-upload-field {
    display: none;
  }
  .scan-loader {
    color: white;
  }
  .stage2-buttons-container {
    margin-top: 20px;
    text-align: center;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      > button {
        width: 100%;
      }
    }
  }
  .upload-passport-and-form-fields-container {
    justify-content: center;
    margin-top: 20px;
  }
`;

const GlobalStyle = createGlobalStyle`
  #modal-component.register-individual-stage1-subprocess-modal {
    top: 10px !important;
  }
  #modal-component.register-individual-stage1-subprocess-modal #modal-content {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const RegisterStage1OcrSubProcess = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        stage1Data: lodashGet(state, 'registerIndividualStagesData.stage1Data'),
        stage1DataDisclaimer: lodashGet(state, 'registerIndividualStagesData.stage1Data.disclaimer')
    }),
    {})(RegisterStage1OcrSubProcessComponent);

export default RegisterStage1OcrSubProcess;
